const menuData = {
  menus: [
    {
      name: "Menu #1",
      chooseFrom: {
        choiceOf: 3,
        items: [
          "Carne Asada",
          "Chicken",
          "Pork Carnitas-Style",
          "Chorizo",
          "Grilled Veggies",
        ],
      },

      tacoBarIncludes:
        "Corn tortillas, Rice, Beans, Chopped Onion, Chopped Cilantro, Limes, Red & Green Salsas.",
      deliveryOption: [
        {
          code: "dropoff",
          name: "Drop off per person",
          price: 12.5,
          chaffersAvailable: true,
        },
        {
          code: "pickup",
          name: "Pickup per person",
          price: 12.5,
          chaffersAvailable: true,
        },
        {
          code: "onsite",
          name: "On-site cooking",
          price: 15.5,
          chaffersAvailable: false,
        },
        {
          code: "whiteglove",
          name: "White glove delivery buffet",
          price: 15.5,
          chaffersAvailable: false,
        },
      ],
    },
    {
      name: "Menu #2",
      chooseFrom: {
        choiceOf: 4,
        items: [
          "Carne Asada",
          "Chicken",
          "Pork Carnitas-Style",
          "Pork Al Pastor-Style",
          "Chorizo",
          "Grilled Veggies",
          "Grilled Fish",
        ],
      },
      tacoBarIncludes:
        "Corn tortillas, Rice, Beans, Chopped Onion, Chopped Cilantro, Limes, Red & Green Salsas, Chipotle Crema, Shredded Cheese.",
      deliveryOption: [
        {
          code: "dropoff",
          name: "Drop off per person",
          price: 14.5,
          chaffersAvailable: true,
        },
        {
          code: "pickup",
          name: "Pickup per person",
          price: 14.5,
          chaffersAvailable: true,
        },
        {
          code: "onsite",
          name: "On-site cooking",
          price: 17.5,
          chaffersAvailable: false,
        },
        {
          code: "whiteglove",
          name: "White glove delivery buffet",
          price: 17.5,
          chaffersAvailable: false,
        },
      ],
    },
    {
      name: "Menu #3",
      chooseFrom: {
        choiceOf: 5,
        items: [
          "Carne Asada",
          "Chicken",
          "Pork Carnitas-Style",
          "Pork Al Pastor-Style",
          "Chorizo",
          "Grilled Veggies",
          "Grilled Fish",
          "Grilled Shrimp",
        ],
      },
      tacoBarIncludes:
        "Corn tortillas, Rice, Beans, Chopped Onion, Chopped Cilantro, Limes, Red & Green Salsas, Chipotle Crema, Pico de Gallo, Shredded Cheese, Pickled Cabbage.",
      deliveryOption: [
        {
          code: "dropoff",
          name: "Drop off per person",
          price: 16.5,
          chaffersAvailable: true,
        },
        {
          code: "pickup",
          name: "Pickup per person",
          price: 16.5,
          chaffersAvailable: true,
        },
        {
          code: "onsite",
          name: "On-site cooking",
          price: 19.5,
          chaffersAvailable: false,
        },
        {
          code: "whiteglove",
          name: "White glove delivery buffet",
          price: 19.5,
          chaffersAvailable: false,
        },
      ],
    },
    {
      name: "Menu #4",
      chooseFrom: {
        choiceOf: 5,
        items: [
          "Ribeye Steak",
          "Mahi Mahi",
          "Chicken",
          "Tri-Tip",
          "Pork Carnitas-Style",
          "Pork Al Pastor-Style",
          "Organic Veggies",
          "Grilled Shrimp",
        ],
      },
      tacoBarIncludes:
        "Chunky Guac & Chips, LaLa Salad, Red & Green Salsa, Chipotle Crema, Cilantro Jalapeno Crema, Onions, Cilantro, Pico De Gallo, Shredded Cheese, Pickled Cabbage, Rice & Beans, Plates, Forks, Serving Utensils & Napkins.",
      deliveryOption: [
        {
          code: "dropoff",
          name: "Drop off per person",
          price: 24.5,
          chaffersAvailable: true,
        },
        {
          code: "pickup",
          name: "Pickup per person",
          price: 24.5,
          chaffersAvailable: true,
        },
        {
          code: "onsite",
          name: "On-site cooking",
          price: 27.5,
          chaffersAvailable: false,
        },
        {
          code: "whiteglove",
          name: "White glove delivery buffet",
          price: 26.5,
          chaffersAvailable: false,
        },
      ],
    },
  ],
  addOns: [
    {
      id: 1003,
      name: "Appetizers",
      description: "All prices are per guest",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        {
          id: 600,
          name: "Chunky Guacamole & Chips (appetizer)",
          price: 3.25,
          note: "* includes plates, napkins and utensils",
        },
        {
          id: 610,
          name: "Pico de Gallo (appetizer)",
          price: 3.25,
          note: "* includes plates, napkins and utensils",
        },
        { id: 601, name: "Elotes - Mexican-style Corn", price: 3.5 },
        { id: 602, name: "Nacho Bar", price: 4.0 },
        { id: 603, name: "Mexican Shrimp Cocktail Shooter", price: 4.0 },
        { id: 604, name: "Charcuterie Boards", price: 0 },
        { id: 605, name: "Artisan Quesadillas", price: 0 },
        { id: 606, name: "Mango Habanero Fresh Salsa", price: 3.0 },
        { id: 607, name: "Chips & Salsa", price: 2.5 },
        { id: 609, name: "Chicken Enchiladas", price: 4.0 },
      ],
    },
    {
      id: 1000,
      name: "Condiments",
      description: "All prices are per guest",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        { id: 112, name: "Quesadillas", price: 2.5 },
        { id: 113, name: "Quesadillas Carnitas, Habanero Cheese", price: 4.0 },
        {
          id: 114,
          name: "Quesadillas Soy Chorizo, Habanero Cheese",
          price: 4.0,
        },
        { id: 102, name: "Chunky Guacamole & Chips (taco bar)", price: 2.75 },
        { id: 103, name: "Pico de Gallo & Chips", price: 2.5 },
        { id: 104, name: "Tortilla Chips", price: 0.75 },
        { id: 105, name: "Shredded Cheese", price: 1.0 },
        { id: 106, name: "Sour Cream", price: 0.75 },
        { id: 107, name: "Hard Shell Tortillas", price: 0.8 },
        { id: 108, name: "Flour Tortillas", price: 0.8 },
        { id: 109, name: "Diced Tomatoes", price: 1.5 },
        {
          id: 110,
          name: "Chipotle Crema - 2 Cups",
          price: 10.0,
          note: "* 15 servings.",
        },
        { id: 111, name: "Spicy Habanero Salsa - 2 Cups", price: 10.0 },
      ],
    },
    {
      id: 1001,
      name: "Proteins",
      description: "All prices are per guest",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        { id: 300, name: "Carnitas", price: 2.5 },
        { id: 301, name: "Grilled Tilapia", price: 2.5 },
        { id: 302, name: "Grilled Shrimp", price: 3.0 },
        { id: 303, name: "Mahi Mahi", price: 4.0 },
        { id: 304, name: "Smoked Brisket Burnt Ends", price: 4.0 },
        { id: 305, name: "Premium Asada", price: 3.5 },
        { id: 306, name: "Al Pastor", price: 2.75 },
      ],
    },
    {
      id: 1002,
      name: "Meat Free Options",
      description: "",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        {
          id: 400,
          name: "Fajita-style Veggies",
          price: 40.0,
          note: "*  Tray 30-35 portions.",
        },
        {
          id: 401,
          name: "Sweet Potatoes & Mushrooms",
          price: 60.0,
          note: "* Tray 30-35 portions.",
        },
        {
          id: 402,
          name: "Soy Chorizo Potatoes & Roasted Peppers",
          price: 60.0,
          note: "* 20+ servings. Includes Chipotle Crema.",
        },
        { id: 404, name: "Lala Mixed Salad", price: 4.0, note: "*  per guest" },
      ],
    },
    {
      id: 1004,
      name: "Drinks",
      description:
        "All prices are per 3.0 gallons. Cups included. Serves approx. 40 guests.",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        {
          id: 200,
          name: "Horchata",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 201,
          name: "Jamaica",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 202,
          name: "Watermelon",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 203,
          name: "Lemonade",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 205,
          name: "Limeade",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 206,
          name: "Pineapple",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 207,
          name: "Cantaloupe",
          price: 65.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 208,
          name: "Iced Tea",
          price: 40.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 204,
          name: "Water Station - Cucumber or Lemon slices",
          price: 35.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 211,
          name: "Water Station - Strawberry or Blueberries",
          price: 35.0,
          note: "* Serves approx. 40 guests.",
        },
        {
          id: 209,
          name: "Coffee Station",
          price: 2.25,
          note: "* per guest - minimum required",
        },
        { id: 210, name: "Aqua Fresca Bottles", price: 0 },
      ],
    },
    {
      id: 1005,
      name: "Desserts",
      description: "",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        {
          id: 700,
          name: "Churros (On-site)",
          price: 0,
          note: "* Made Fresh On Site. Includes - Chocolate & Caramel Syrup and Ice Cream",
        },
        { id: 701, name: "Mixed Fruit Buffet", price: 3.75 },
        { id: 704, name: "Churros (Delivery)", price: 3.75 },
        { id: 705, name: "Mexican Flan", price: 4.0 },
        { id: 706, name: "Tres Leches Cake ", price: 3.75 },
      ],
    },
    {
      id: 1006,
      name: "Late night snacks",
      description: "",
      quantityStep: 1,
      initialQuantity: 1,
      items: [
        { id: 800, name: "Reduced Taco Menu", price: 0 },
        { id: 801, name: "Artisan Quesadillas", price: 0 },
        { id: 804, name: "Grilled Cheese Sandwiches", price: 0 },
      ],
    },
  ],
  specialOffers: {
    // Sunday is index 0, Monday is index 1, etc.
    // January is index 0, February is index 1, etc.
    // Selected delivery: dropOff = 0, pickup = 1, onsite = 2
    churros: {
      title: "Complimentary Churros",
      description:
        "If you place an order similar to this quote, you qualify for complimentary churros!",
      minimum: 3000,
      isActive: true,
      offeredOnDays: [0, 1, 2, 3, 4, 5],
      offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      deliveryOption: 2,
      occasion: [],
    },
    churrosSaturday: {
      title: "Complimentary Churros",
      description:
        "If you place an order similar to this quote, you qualify for complimentary churros!",
      minimum: 3000,
      isActive: true,
      offeredOnDays: [6],
      offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      deliveryOption: 2,
      occasion: [],
    },
    tasting: {
      title: "Complimentary tasting",
      description:
        "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
      minimum: 1700,
      isActive: true,
      offeredOnDays: [0, 1, 2, 3, 4],
      offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      deliveryOption: 2,
    },
    tastingSaturday: {
      title: "Complimentary tasting",
      description:
        "This quote qualifies for complimentary tasting! We will send you a link to schedule your tasting session.",
      minimum: 1900,
      isActive: true,
      offeredOnDays: [5, 6],
      offeredMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      deliveryOption: 2,
    },
  },
};

export default menuData;
