const isFridayOrSaturday = (value) => {
  const date = new Date(value);
  const day = date.getDay();
  return day === 5 || day === 6 ? true : false;
};

const nthNumber = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

const getFormattedDateForDisplay = (date) => {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  return `${month} ${day}${nthNumber(day)}`;
};

const debounce = (fn, delay) => {
  let timeoutID;
  return function (...args) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

export { isFridayOrSaturday, nthNumber, getFormattedDateForDisplay, debounce };
